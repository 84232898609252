import { useEffect } from 'react';

export const DocumentationUrl = 'https://nibesustainability.sharepoint.com/:f:/s/RETHiNKdocumentation/Eu-BsKGYZTVDmMuaB_Tk4ikBcmq3_okoOIq_9FYk0uAkAQ?e=a4XA6d';

export const Documentation = () => {
  useEffect(() => {
    window.location = DocumentationUrl;
  }, []);
  return null;
};
