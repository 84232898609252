import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RethinkSuiteLayerGuards } from 'apps/guards';
import { useSuiteLayoutContext } from 'context/SuiteLayoutContext';
import { useAllMembershipsWithPermissions, useCurrentMembership, useMemberships } from 'hooks/useMemberships';

import { AppsType } from './types';
import settingsIcon from '../../assets/icons/tools.svg';
import userIcon from '../../assets/icons/user.svg';

const noMembershipApp = {
  title: '',
  href: '',
  color: '#262D38',
};

const apps: AppsType = {
  lca: {
    name: 'lca',
    title: 'LCA',
    color: '#28B56B',
    href: '/lca',
    subTitle: 'Life Cycle Assessment',
  },
  ccf: {
    name: 'ccf',
    title: 'CCF',
    color: '#1B6D40',
    href: '/ccf',
    subTitle: 'Organizational Environmental Footprint',
  },
  management: {
    name: 'management',
    title: 'Management & Settings',
    color: '#262D38',
    href: '/management',
    secondary: true,
    icon: settingsIcon,
  },
  admin: {
    name: 'admin',
    title: 'Admin',
    color: '#8399A5',
    href: '/admin',
    secondary: true,
    icon: userIcon,
  },
  share: {
    name: 'share',
    title: 'SHARE',
    color: '#BFF90B',
    href: '/share',
  },
};

export const useHeaderData = () => {
  const { pathname } = useLocation();
  const app = pathname.split('/').filter((p) => !!p)[0];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const allMemberships = useMemberships();
  const allMembershipsWithRoles = useAllMembershipsWithPermissions();
  const { selectedMembership, changeMembership, clearSelectedMembership } = useCurrentMembership();

  const { headerConfig } = useSuiteLayoutContext();

  const allMembershipsData = allMemberships?.data;

  // @ts-expect-error state isn't typed
  const recentMembershipIds: number[] | undefined = useSelector(s => s.user?.recentMembershipIds);

  const sortedMemberships = useMemo(() => {
    if (!allMembershipsData || !recentMembershipIds) return allMembershipsData;
    return [
      ...recentMembershipIds.flatMap(id => allMembershipsData.find(m => m.id === id) || []),
      ...allMembershipsData.filter(m => !recentMembershipIds.includes(m.id)),
    ];
  }, [allMembershipsData, recentMembershipIds]);
  return useMemo(
    () => (!selectedMembership ? {
      currentApp: {
        name: undefined,
        color: noMembershipApp.color,
      },
      availableApps: {} as AppsType,
      onChangeMembership: changeMembership,
      clearSelectedMembership,
    } : {
      currentApp: {
        color: apps[app]?.color || apps.admin.color,
        name: apps[app]?.name as unknown as AppsType['name'] | undefined,
      },
      availableApps: {
        ...apps,
        share: allMembershipsWithRoles?.some(
          m => m.roles.some(r => RethinkSuiteLayerGuards.share.some(p => r.permissions[p])),
        ) ? apps.share : undefined,
        admin: allMembershipsWithRoles?.some(
          m => m.roles.some(r => RethinkSuiteLayerGuards.admin.some(p => r.permissions[p])),
        ) ? apps.admin : undefined,
      },
      items: headerConfig.items.flatMap((it) => {
        if ('items' in it) {
          const sub = it.items.filter(i => !i.hidden);

          return sub.length === 0 ? [] as (typeof it)[] : {
            ...it,
            items: sub,
          } as typeof it;
        }

        return it.hidden ? [] as (typeof it)[] : it;
      }) as typeof headerConfig.items,
      searchBar:
       headerConfig.searchBar,
      callToAction: headerConfig.callToAction?.items?.some(i => !i.hidden)
        ? headerConfig.callToAction : undefined,
      selectedMembership,
      availableMemberships: sortedMemberships || [],
      onChangeMembership: changeMembership,
      clearSelectedMembership,
    }),
    [
      sortedMemberships,
      app, changeMembership,
      headerConfig,
      selectedMembership,
      clearSelectedMembership,
      allMembershipsWithRoles,
    ],
  );
};
