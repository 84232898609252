import React from 'react';

import { NavLink } from 'react-router-dom';

import * as S from './styled';

export const AppLink: React.FC<{
  href: string;
  title: string;
  subTitle?: string;
  appColor: string;
}> = ({
  href, title, subTitle, appColor,
}) => (
  <NavLink to={href}>
    <S.AppItem>
      <S.AppItemLogo color={appColor} />
      <div>
        <S.AppItemName>{title}</S.AppItemName>
        {subTitle && <S.AppItemTitle>{subTitle}</S.AppItemTitle>}
      </div>
    </S.AppItem>
  </NavLink>
);
