// SVGs
import finalRejected from 'assets/calculationsIcons/FinalRejected';
import progress from 'assets/calculationsIcons/Progress';
import rejected from 'assets/calculationsIcons/Rejected';
import review from 'assets/calculationsIcons/Review';
import submitted from 'assets/calculationsIcons/Submitted';
import valid from 'assets/calculationsIcons/Valid';
import verified from 'assets/calculationsIcons/Verified';

// COLORS
export const YELLOW = '#FECF07';
export const SKY = '#19b5ff';
export const DARK_BLUE = '#202538';
export const DARK_BLUE_400 = '#333950';
export const LIGHT_BLUE = '#F4F8FA';
export const BLUE = '#5885A4';
export const MOSTARD = '#DCB40B';
export const RED = '#BD5230';
export const GREY = '#F4F8FA';
export const GREEN = '#70876E';
export const black = (opacity) => `rgba(0,0,0,${opacity})`;
export const backdrop = black(0.4);

export const palette = {
  purple: {
    500: 'hsl(266, 69.7%, 36.3%)',
    100: 'hsl(266, 100%, 95.8%)',
  },
  green: {
    50: 'hsla(115, 100%, 95%, 1)',
    500: 'hsl(118, 42.9%, 27.5%)',
    300: 'hsl(114, 76.5%, 47.9%)',
  },
  yellow: {
    50: 'hsla(48, 100%, 93%, 1)',
    500: 'hsl(50, 100%, 50%)',
    300: 'hsl(42, 100%, 44.5%)',
  },
  petrol: {
    500: 'hsl(204.5, 58.9%, 41.4%)',
    100: 'hsl(204, 55.6%, 91.2%)',
  },
  orange: {
    300: 'hsl(18, 100%, 40.2%)',
  },
  blue: {
    50: 'hsl(222, 71.4%, 97.3%)',
    200: 'hsl(221, 100%, 91.5%)',
    300: 'hsl(221, 80.1%, 47.3%)',
    100: 'hsl(216, 41.9%, 93.9%)',
  },
  clay: {
    100: 'hsl(0, 9.1%, 97.8%)',
    200: 'hsla(40, 9%, 93%, 1)',
    300: 'hsla(0, 0%, 92%, 1)',
    400: 'hsl(40, 14.9%, 72.4%)',
    500: 'hsla(0, 0%, 67%, 1)',
    700: 'hsl(226, 15.7%, 16.3%)',
    800: 'hsl(0, 0%, 10.8%)',
  },
  red: {
    300: 'hsl(0, 100%, 50%)',
    400: 'hsl(0, 100%, 32.1%)',
    50: 'hsl(0, 100%, 94.8%)',
  },
};

export const DashboardButton = {
  background: '#FDFEFF',
  color: '#202538',
  border: '1px solid #E8EDEF',
  fontWeight: 500,
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
};

export const statusStyle = {
  Draft: {
    id: 'draft',
    color: '#dee3e2',
    icon: finalRejected,
    text: 'Draft',
  },
  Progress: {
    id: 'in-progress',
    buttonBackgroundColor: '#70876E',
    backgroundColor: '#5885A4',
    color: 'white',
    icon: progress,
    iconColor: 'white',
    text: 'In progress',
  },
  Incomplete: {
    id: 'incomplete',
    buttonBackgroundColor: '#FFE2D6',
    icon: progress,
    iconColor: 'red',
    text: 'Incomplete',
    color: 'red',
  },
  Valid: {
    id: 'valid',
    color: '#51864C',
    icon: valid,
    text: 'Valid',
  },
  Submitted: {
    id: 'submitted',
    color: '#5885A4',
    icon: submitted,
    text: 'Submitted',
  },
  Review: {
    id: 'in-review',
    color: '#336282',
    icon: review,
    text: 'In review',
  },
  Verified: {
    id: 'verified',
    color: '#51864C',
    icon: verified,
    text: 'Verified',
  },
  Rejected: {
    id: 'rejected',
    color: '#BD5230',
    icon: rejected,
    text: 'Rejected',
  },
  FinalRejected: {
    id: 'declined',
    color: '#BF2E00',
    icon: finalRejected,
    text: 'Final Rejected',
  },
  Completed: {
    id: 'completed',
    color: '#51864C',
    icon: verified,
    text: 'Completed',
  },
};

// Footer Height
export const footerHeight = '6rem';

// Padding Overview rows
export const paddingRows = '1rem 1.25rem';

// Sizes
export const AsideWidth = '16.75rem';
export const InputHeight = '2.5rem';
export const InputWidth = '715px';
export const FormWidth = 'auto';

// MAX WIDTH
export const MAX_WIDTH = '1280px';

// Navs heigth
export const NAV_HEIGHT = '4rem';

export const styledButton = {
  background: 'white',
  color: DARK_BLUE,
  border: '1px solid #E2E2D2',
  height: '2.81rem',
  minWidth: '11.5rem',
  boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
};

export const styledButtonForHeaderInViews = {
  background: 'white',
  color: DARK_BLUE,
  height: '2.81rem',
  minWidth: '9.0625rem',
  textAlign: 'center',
  border: '1px solid #FFFFFF',
  boxSizing: 'border-box',
  boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '2px',
  marginLeft: '2.3rem',
};
