import React from 'react';

import { useTranslation } from 'react-i18next';

import { DocumentationUrl } from 'routes/Documentation';

import { AppLink } from './AppLink';
import * as S from './styled';
import { ReactComponent as BookIcon } from '../../../../assets/icons/book-icon.svg';
import { ReactComponent as ChatIcon } from '../../../../assets/icons/chat.svg';
import { ReactComponent as ChevronDown } from '../../../../assets/icons/chevron-down-white.svg';
import { Divider } from '../../../Divider/styled';
import { Dropdown } from '../../../Dropdown';
import { AppsType } from '../../types';

export const AppMenu: React.FC<{
  appName?: AppsType['name'];
  availableApps: Partial<AppsType>;
  color: string;
}> = ({
  appName, color, availableApps,
}) => {
  const { t } = useTranslation();
  return (
    <Dropdown
      toggleComponent={(
        <S.MenuToggle color={color}>
          <S.MenuLogo width={20} />
          <ChevronDown width={12} />
          {appName && t(`layerSwitcher.${appName}.title`)}
        </S.MenuToggle>
      )}>
      <S.MenuDropdown>
        {Object.keys(availableApps).length > 0 ? <div>{t('layerSwitcher.title')}</div> : null}
        {Object.keys(availableApps).filter(key => !availableApps[key]?.secondary)
          .map((key) => {
            const app = availableApps[key];
            return app && (
              <React.Fragment key={app.href}>
                <AppLink
                  appColor={app.color}
                  href={app.href}
                  subTitle={t(`layerSwitcher.${app.name}.subTitle`)}
                  title={t(`layerSwitcher.${app.name}.title`)}
                />
              </React.Fragment>
            );
          })}
        {Object.keys(availableApps).length > 0 ? <Divider /> : null}
        {Object.keys(availableApps).filter(key => availableApps[key]?.secondary).map((key) => {
          const app = availableApps[key];
          return app && (
            <S.MenuLink key={key} to={app.href}>
              <img alt="" src={app.icon} />
              {t(`layerSwitcher.${app.name}.title`)}
            </S.MenuLink>
          );
        })}
        {Object.keys(availableApps).length > 0 ? <Divider /> : null}
        <S.MenuLink as="a" href={DocumentationUrl} target="_blank">
          <BookIcon />
          <div>
            {t('layerSwitcher.documentation.title')}
            <span>{t('layerSwitcher.documentation.subTitle')}</span>
          </div>
        </S.MenuLink>
        <S.MenuLink as="a" href={`mailto:helpdesk@rethink-epd.com?body=${encodeURIComponent(t('layerSwitcher.help.emailBody'))}`}>
          <ChatIcon />
          <div>
            {t('layerSwitcher.help.title')}
            <span>{t('layerSwitcher.help.subTitle')}</span>
          </div>
        </S.MenuLink>

      </S.MenuDropdown>
    </Dropdown>
  );
};
