import React, { PropsWithChildren } from 'react';

import useSWR from 'swr';

import { ReactComponent as Logo } from 'assets/logo.svg';

import * as S from './Login/Login.styled';

const MAINTENANCE_MODE_ENABLED = false;

const sessionStorageBypassFetcher = async () => sessionStorage.getItem('maintenance_bypass') === 'true';

export const MaintenanceModeAwareWrapper = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const { data: bypass } = useSWR('maintenance_check', sessionStorageBypassFetcher, { isPaused: () => !MAINTENANCE_MODE_ENABLED });
  return MAINTENANCE_MODE_ENABLED && !bypass ? <ComingSoonLandingPage /> : children as JSX.Element;
};

const ComingSoonLandingPage = () => (
  <S.StyledContainer>
    <S.StyledColumn>
      <S.StyledTitle>
        Welcome to
        {' '}
        <Logo />
      </S.StyledTitle>
      {'R<THiNK is currently not available due to planned maintenance.'}
      <br />
      We will be back soon with an improved version of the app.
    </S.StyledColumn>
  </S.StyledContainer>
);
