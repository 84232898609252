import {
  combineReducers, createStore, applyMiddleware, compose,
} from 'redux';
import { reducer as formReducer } from 'redux-form';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reduxThunk from 'redux-thunk';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import auth from './auth/auth.reducer';
import company from './company/company.reducer';
import mercure from './mercure/mercure.reducer';
import sidebar from './sidebar/sidebar.reducer';
import user from './user/user.reducer';

const enableReduxDevTools = process.env.NODE_ENV !== 'production'
  && window.__REDUX_DEVTOOLS_EXTENSION__; // eslint-disable-line no-underscore-dangle

const persistConfig = {
  key: 'root',
  storage,
  // stateReconciler: autoMergeLevel2,
  // Whitelist (Save Specific Reducers)
  whitelist: [
    'auth',
    'user',
  ],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
    'company',
    'calculations',
    'form',
  ],
};

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: [
    'email',
    'userToken',
  ],
};

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: [
    'id',
    'email',
  ],
};

const mercurePersistConfig = {
  key: 'mercure',
  storage,
  whitelist: [
    'importTopic',
    'updateMercure',
  ],
};

const sidebarPersistConfig = {
  key: 'sidebar',
  storage,
  whitelist: [
    'routeName',
  ],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  user: persistReducer(userPersistConfig, user),
  mercure: persistReducer(mercurePersistConfig, mercure),
  sidebar: persistReducer(sidebarPersistConfig, sidebar),
  company,
  form: formReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  enableReduxDevTools ? compose(
    applyMiddleware(reduxThunk),
    enableReduxDevTools && enableReduxDevTools(),
  ) : compose(applyMiddleware(reduxThunk)),
);

const persistor = persistStore(store);

export {
  store,
  persistor,
};
