import * as calculationsApi from './calculations';
import * as companiesApi from './companies';
import * as countriesApi from './countries';
import * as EnvironmentalProfileApi from './EnvironmentalProfile';
import * as foldersApi from './folders';
import * as importsApi from './imports';
import * as inputsApi from './inputs';
import * as lifeCycleStagesApi from './lifeCycleStages';
import * as locationsApi from './locations';
import * as methodsApi from './methods';
import * as permissionsApi from './permissions';
import * as profilesApi from './profiles';
import * as realtimeApi from './realtime_and_progress';
import * as revisionsApi from './reviews';
import * as SupplierApi from './Supplier';
import * as usersApi from './users';
import * as WasteScenarioApi from './WasteScenario';

export { default as versions } from './versions';
export { default as standardunits } from './standardunits';
export const calculations = calculationsApi;
export const inputs = inputsApi;
export const folders = foldersApi;
export const locations = locationsApi;
export const countries = countriesApi;
export const methods = methodsApi;
export const reviews = revisionsApi;
export const users = usersApi;
export const profiles = profilesApi;
export const EnvironmentalProfile = EnvironmentalProfileApi;
export const Supplier = SupplierApi;
export const WasteScenario = WasteScenarioApi;
export const companies = companiesApi;
export const realtime = realtimeApi;
export const imports = importsApi;
export const permissions = permissionsApi;
export const lifeCycleStages = lifeCycleStagesApi;

export { default } from './network';
