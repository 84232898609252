import React from 'react';

import { RethinkSuiteLayerGuards } from './guards';

export const RethinkSuiteLayers = {
  admin: {
    requireOneOf: RethinkSuiteLayerGuards.admin,
    entryPoint: React.lazy(() => import('./admin')),
    noPermissionPage: null,
  },
  lca: {
    requireOneOf: RethinkSuiteLayerGuards.lca,
    entryPoint: React.lazy(() => import('./lca')),
    noPermissionPage: null,
  },
  ccf: {
    requireOneOf: RethinkSuiteLayerGuards.ccf,
    entryPoint: React.lazy(() => import('./ccf')),
    noPermissionPage: React.lazy(() => import('./ccf/routes/Preview').then(m => ({ default: m.Preview }))),
  },
  management: {
    requireOneOf: RethinkSuiteLayerGuards.management,
    entryPoint: React.lazy(() => import('./management')),
    noPermissionPage: null,
  },
  share: {
    requireOneOf: RethinkSuiteLayerGuards.share,
    entryPoint: React.lazy(() => import('./share')),
    noPermissionPage: null,
  },
} as const;
