import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as Logo } from '../../../../assets/icons/logo.svg';

export const MenuToggle = styled.div<{color: string}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-family: "ReadexPro";
  padding: 22px 24px;
  height: 64px;
  min-width: 242px;
  background-color: ${({ color }) => color};
  color: ${({ color }) => (color === '#FFD600' || color === '#BFF90B' ? '#000' : '#fff')};
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  svg path {
   stroke: ${({ color }) => (color === '#FFD600' || color === '#BFF90B' ? '#000' : '#fff')};
   fill: ${({ color }) => (color === '#FFD600' || color === '#BFF90B' ? '#000' : '#fff')};
  }

  svg + svg path {
    fill: none;
  }
`;

export const MenuLogo = styled(Logo)`
  width: 20px !important;
  margin-right: 10px;

  + svg {
    margin-right: 4px;
  }
`;

export const MenuDropdown = styled.div`
  background-color: ${({ theme }) => theme.palette.clay[800]};
  padding: 16px 24px 8px 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 352px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  a {
    display: flex;
    color: #fff;
    position: relative;

    &:after {
      transition: 250ms linear;
    }

    &:hover {
      &:after {
        content: '';
        position: absolute;
        left: -8px;
        right: -8px;
        bottom: 4px;
        top: 4px;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
      }
    }
  }
`;

export const AppItemLogo = styled.div<{ color: string }>`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: ${({ color }) => color};
  flex-shrink: 0;
`;

export const AppItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
`;

export const AppItemName = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

export const AppItemTitle = styled.div`
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
`;

export const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  span {
    display: block;
    color: rgba(255, 255, 255, 0.5);
    font-weight: normal;
    font-size: 10px;
  }
`;
