import { AccessControlList } from 'types/AccessControlLists';

import { Membership } from 'types/Membership';

import { api } from './network';

export const getPermissions = (membership?: Membership) => api.get<{data: {permissions: AccessControlList}}>('/permissions', {
  // @ts-expect-error not typed, but new API support useMembership param
  overrideMembership: membership,
  // @ts-expect-error not typed?
}).then(res => res.data.data.permissions as AccessControlList);
