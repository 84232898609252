import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import HTML from 'components/RenderHTML';

import {
  Label,
  Hint,
  FieldWrapper,
  ErrorContainer,
  HintWrapper,
  InputWrapper,
  ContentWrapper,
  StyledLabel,
  ReadOnlyLabel,
  MaxWidth,
  ExpandForm,
} from './field.styled';

export const withLabels = (Comp) => {
  const ComposedComponent = memo(
    ({
      name,
      count,
      translationName,
      id,
      translation,
      children,
      input,
      meta,
      fallbackLabel = null,
      // namespace = 'forms',
      ...other
    }) => {
      const { t } = useTranslation();
      const inputName = name || input?.name || id;
      const props = other;

      let hasLabel = translation?.label
        ?? (t(`${inputName}.label`).endsWith('.label')
          ? fallbackLabel
          : t(`${inputName}.label`));

      if (translationName && count) { hasLabel = t(`${translationName}.label`, count); }

      const hasHint = translation?.hint
        ?? (t(`${inputName}.hint`).endsWith('.hint')
          ? null
          : t(`${inputName}.hint`));

      const renderHint = hasHint && (
        <Hint hide={meta?.active}>
          <HTML html={hasHint} />

        </Hint>
      );

      const renderLabel = hasLabel && (
        <Label {...props?.labelStyle} htmlFor={inputName}>
          {hasLabel}
          {hasHint && <InfoIcon />}
          {!props?.fullInputWidth && (
            <HintWrapper>
              {renderHint}
            </HintWrapper>
          )}
        </Label>
      );
      const placeholder = props.placeholder
        || (translation?.placeholder
          ?? (t(`${inputName}.placeholder`).endsWith('.placeholder')
            ? undefined
            : t(`${inputName}.placeholder`)));

      if (input) props.input = input;
      if (meta) props.meta = meta;
      if (inputName) {
        props.name = inputName;
        props.id = inputName;
      }
      if (placeholder) props.placeholder = placeholder;

      return (
        <FieldWrapper
          {...props?.wrapperStyle}>
          <Label>{renderLabel}</Label>

          <InputWrapper className={meta?.touched && meta?.error ? 'invalid' : ''}>
            <MaxWidth fullInputWidth={props?.fullInputWidth}>
              <Comp {...props}>{children}</Comp>
              {/* {meta?.touched && meta?.error && <ErrorIcon />} */}
            </MaxWidth>
            {!props?.fullInputWidth
            && (
              <ExpandForm>
                {props?.custom
                  && props?.custom()}
              </ExpandForm>
            )}
          </InputWrapper>

          {meta?.touched && meta?.error && (
            <ErrorContainer>{meta?.touched && meta?.error}</ErrorContainer>
          )}
        </FieldWrapper>
      );
    },
  );

  return ComposedComponent;
};

export const ReadOnlyField = memo(
  ({
    name,
    translatedLabel,
    id,
    translation,
    children,
    input,
    meta,
    translationParams,
    // namespace = 'forms',
    ...other
  }) => {
    const { t } = useTranslation();
    const inputName = name || input?.name || id;
    const props = other;

    function hasLabel() {
      return t(`${inputName}.label`, translationParams).endsWith('.label')
        ? null
        : t(`${inputName}.label`, translationParams);
    }

    const hasName = translation?.name
      ?? (t(`${inputName}.name`, translationParams).endsWith('.name')
        ? hasLabel()
        : t(`${inputName}.name`, translationParams));

    const renderLabel = (hasName || !!translatedLabel) && (
      <ReadOnlyLabel {...props?.labelStyle} htmlFor={inputName || translatedLabel}>
        {hasName || translatedLabel}
      </ReadOnlyLabel>
    );

    if (input) props.input = input;
    if (inputName) {
      props.name = inputName;
      props.id = inputName;
    }

    return (
      <FieldWrapper {...props?.wrapperStyle}>
        <Label>{renderLabel}</Label>

        <InputWrapper>{children}</InputWrapper>
      </FieldWrapper>
    );
  },
);

/**
 * @deprecated use ImageViewField instead
 */
export const ReadOnlyImageField = memo(({ name, alt, src }) => (
  <ReadOnlyField name={name}>
    {src ? <img alt={alt} src={src} style={{ maxWidth: 500 }} /> : '-'}
  </ReadOnlyField>
));

export const InputView = ({ label, children }) => (
  <div style={{ marginBottom: '1.5rem' }}>
    <StyledLabel>{label}</StyledLabel>
    <ContentWrapper>{children}</ContentWrapper>
  </div>
);

export default withLabels(({ children }) => children);
