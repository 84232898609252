import React from 'react';

import { Avatar } from 'appComponents/Avatar';
import { Membership } from 'types/Membership';

import * as S from './styled';
import chevronDownWhite from '../../../../assets/icons/chevron-down-white.svg';
import { useHeaderData } from '../../useHeaderData';

export const UserMenuItem = ({
  handleClick,
  handleClose,
  membership,
  expanded,
}: {
  handleClick: () => void;
  handleClose: () => void;
  membership: Membership;
  expanded: boolean;
}) => {
  const { selectedMembership, onChangeMembership } = useHeaderData();

  return (
    <S.CtaListItem expanded={expanded}>
      <div>
        <S.Cta key={membership['@id']} onClick={handleClick} type="button">
          <Avatar size="36px" title={membership.company.name} />
          <img alt="" src={chevronDownWhite} width={16} />
        </S.Cta>
        <S.CtaList>
          {membership.roles.map((role) => (
            <S.CtaListButton
              key={role['@id']}
              onClick={() => {
                onChangeMembership(membership, role);
                handleClose();
              }}
              selected={selectedMembership?.role?.['@id'] === role?.['@id']}
              type="button">
              {role.view}
            </S.CtaListButton>
          ))}
        </S.CtaList>
      </div>
    </S.CtaListItem>
  );
};
