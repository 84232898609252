import React, { InputHTMLAttributes } from 'react';

import searchIcon from 'assets/icons/search.svg';

import * as S from './styled';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  helperText?: string;
  error: string;
};

export const Input: React.FC<InputProps> = ({
  label, helperText, error, ...props
}) => (
  <S.InputContainer>
    {label && <S.InputLabel>{label}</S.InputLabel>}
    <S.InputWrapper>
      {props.type === 'search' && <img alt="search" src={searchIcon} />}
      <S.InputElement {...props} isSearch={props.type === 'search'} />
    </S.InputWrapper>
    {helperText && <S.InputHelperText>{helperText}</S.InputHelperText>}
    {error && <S.InputError>{error}</S.InputError>}
  </S.InputContainer>
);
