import * as types from './sidebar.types';

const initialState = {
  isHidden: false,
};

const sidebar = (state = initialState, action) => {
  switch (action.type) {
  case types.HIDE_SIDEBAR: {
    return { ...state, isHidden: !state.isHidden };
  }

  default:
    return state;
  }
};

export default sidebar;
