import styled from 'styled-components/macro';

const height = 22;

export const Label = styled.label`
  font-size: 0.75rem;
  margin-right: .5em;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: ${height}px;
    line-height: 1;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: ${height - 4}px;
    width: ${height - 4}px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: ${({ theme }) => theme.palette.blue[300]};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${({ theme }) => theme.palette.blue[300]};
  }

  input:checked + .slider:before {
    transform: translateX(14px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
