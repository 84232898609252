import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f4f8fa;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  padding: 21px 40px 35px 43px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(57, 57, 57, 0.2);
  border-radius: 2px;
`;

export const MembershipsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 446px;
  margin: auto;
`;

export const MembershipsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const MembershipGroup = styled.div`
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
  gap: 16px;
`;

export const MajorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 48px 30px;
  margin-bottom: 38px;
  gap: 60px;
  max-width: 1380px;
  margin: 0 auto;
`;

export const UserName = styled.span`
  font-weight: bold;
`;

export const SignOut = styled.span`
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
  cursor: pointer;
`;

export const MembershipName = styled.span`
  font-size: 14px;
  line-height: 21px;
`;

export const Name = styled.span`
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const Role = styled.div`
  font-size: 12px;
  line-height: 18px;
`;

export const DashboardTop = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  > div:last-child {
    grid-column: 4 / 5;
  }
`;

export const CompanyContainer = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  min-height: ${({ size = 'sm' }) => (size === 'sm' ? '128px' : '336px')};
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CardContainer = styled.div`
  height: 48px;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;

  img {
    width: 18px;
  }
`;

export const Options = styled.img`
  cursor: pointer;
`;

export const FooterContainer = styled.div`
  margin-bottom: 2rem;
  border-top: 1px solid #e4e4e4;
  padding-top: 1.625rem;
`;

export const Title = styled.h4`
  font-weight: 500;
  font-size: 14px;
  margin: 0;
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 20px;
`;
