import React from 'react';
import { NavLink } from 'react-router-dom';

import type { useHeaderData } from '../../useHeaderData';

import * as S from './styled';
import chevronDown from '../../../../assets/icons/chevron-down.svg';
import { Dropdown } from '../../../Dropdown';

export const Nav = ({ items }: {items: ReturnType<typeof useHeaderData>['items']}) => (
  <S.Nav>
    {items?.map((it) => ('items' in it ? (
      <Dropdown
        key={it.title}
        align="right"
        toggleComponent={(
          <S.SubNavToggle>
            {it.title}
            <img alt="" src={chevronDown} width={12} />
          </S.SubNavToggle>
        )}
        variant="secondary">
        {it.items?.map((item) => (
          <NavLink key={item.href} to={item.href}>{item.title}</NavLink>
        ))}
      </Dropdown>
    ) : !!it && (
      <NavLink key={it.href} to={it.href}>
        {it.title}
      </NavLink>
    )))}
  </S.Nav>
);
