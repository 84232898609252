import 'react-toastify/dist/ReactToastify.css';
import './toast.css';
import { css } from 'glamor';
import i18next from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

// icons
import styled from 'styled-components';

import alert from 'assets/icons/alert-error.svg';
import closeIcon from 'assets/icons/close.svg';
import successIcon from 'assets/success.svg';

const LinkTo = styled(Link)`
  text-decoration: none;
`;

const content = (text, type, link) => (
  <div style={{ display: 'flex', alignItems: 'center', fontSize: '.875rem' }}>
    <img
      alt="icon"
      src={type === 'success' ? successIcon : alert}
      style={{ marginRight: '10px' }}
    />
    {link
      ? <LinkTo to={{ pathname: link?.to, state: { routeName: link?.routeName } }}>{text}</LinkTo>
      : <p>{text}</p>}
  </div>
);

const styleToast = () => ({
  closeButton: () => <img alt="icon" src={closeIcon} />,
  className: css({
    width: '26rem',
    right: '6.5rem',
  }),
  position: toast.POSITION.TOP_RIGHT,
});

export const alertToast = (message, type, link) => (type === 'success'
  ? toast.success(content(message, type, link), styleToast())
  : toast.error(content(message, type, link), styleToast()));

export const errorToast = (err, message) => {
  if (err?.response?.data?.['hydra:description']) {
    return alertToast(i18next.t(err.response.data['hydra:description']), 'error');
  }
  if (!message && err?.response?.data?.violations?.[0]?.propertyPath) {
    const { propertyPath, message: violationMessage } = err.response.data.violations[0];

    const messageKey = `${propertyPath?.replace('.', '')}.${violationMessage?.replace('.', '')}`;

    if (i18next.exists(messageKey)) {
      return alertToast(i18next.t(messageKey), 'error');
    }
  }
  return alertToast(message || 'Error', 'error');
};

export const networkErrorToast = (networkErr) => {
  const violationMessage = networkErr.response?.data?.violations?.[0].message;

  return errorToast(networkErr, violationMessage);
};
