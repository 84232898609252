import React from 'react';

import * as S from './styled';

type LinkButtonProps = {
  as: 'a' | 'button';
  variant?: 'primary' | 'secondary';
  type?: 'button' | 'submit'
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
  fullWidth?: boolean;
  color?: 'primary' | 'danger';
  disabled?: boolean
};

export const LinkButton = ({
  as = 'a',
  href,
  onClick,
  children,
  fullWidth = false,
  variant = 'primary',
  color = 'primary',
  ...props
}: LinkButtonProps) => {
  if (as === 'a') {
    return (
      <S.StyledLinkButton
        fullWidth={fullWidth}
        href={href}
        onClick={onClick}
        variant={variant}
        {...props}>
        {children}
      </S.StyledLinkButton>
    );
  }

  return (
    <S.StyledButton
      color={color}
      fullWidth={fullWidth}
      onClick={onClick}
      variant={variant}
      {...props}>
      {children}
    </S.StyledButton>
  );
};
