import styled from 'styled-components';

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  font-weight: 600;
  font-size: 12px;
  line-height: 24px;

  span {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const AvatarImage = styled.img<{ size: string }>`
  border-radius: 50%;
  border: 1px solid #eaeaea;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  object-fit: contain;
`;
