import styled from 'styled-components';

export const MembershipGroup = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
  gap: 16px;
`;
