import styled from 'styled-components';

export const SubNavToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SubNav = styled.div`
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  a {
    color: #fff;
    padding: 12px 16px;
    display: block;
    transition: color 250ms;
    line-height: normal;

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 24px;
  margin-right: auto;

  a {
    font-family: "ReadexPro";
  }

  ${SubNavToggle}, > a {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.clay[800]};
    position: relative;
    cursor: pointer;
    font-family: "ReadexPro";
    display: flex;
    gap: 4px;
    text-transform: capitalize;

    &.active {
      font-weight: 500;
    }
  }
`;
