import { errorToast } from 'config/toast';

import network from './network';

export const getAll = (params) => network.get('/life_cycle_stages', {
  params: {
    ...params,
    limit: 50,
    'order[code]': 'asc',
  },
})
  .then(res => res?.['hydra:member'])
  .catch(errorToast);
