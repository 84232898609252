import styled from 'styled-components';

export const CardContainer = styled.button`
  height: 48px;
  border: 1px solid #eaeaea;
  display: flex;
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;  cursor: pointer;

  img {
    width: 18px;
  }
`;

export const Role = styled.div`
  font-size: 12px;
  line-height: 18px;
`;

export const Options = styled.img`

`;
