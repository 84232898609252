import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';

import { AllMemberships } from 'appComponents/Dashboard/Memberships';
import { Loader } from 'appComponents/Loader/styled';
import { Placeholder } from 'components/Splash/Loading';
import { useSetHeaderConfig } from 'context/SuiteLayoutContext';
import { useAllMembershipsWithPermissions, usePermissions } from 'hooks/useMemberships';
import { MembershipsContainer } from 'routes/RethinkDashboard.styled';
import { AccessControlListKey } from 'types/AccessControlLists';

export type ProtectedRouteProps = {
  /**
   * Require at least one permission of the array to be granted, takes precedence over require
   */
  requireOneOf?: AccessControlListKey[]
  /**
   * Require all permissions in the array to be granted.
   */
  require?: AccessControlListKey[]
  behavior?: 'go back' | 'select membership'
  /**
   * When behavior is set to 'select membership', if no membership applies, render this fallback. Otherwise, goes back.
   */
  fallback?: React.ComponentType | null
  children?: React.ReactNode
  /**
   * If true, immediately deny access to the route, regardless of permissions. Takes precedence over require and requireOneOf
   */
  authorized?: boolean
}

export const ProtectedRoute = ({
  requireOneOf, require, behavior = 'go back', children, authorized, fallback,
}: ProtectedRouteProps) => {
  const { permissions, selectedMembership } = usePermissions();
  const allMemberships = useAllMembershipsWithPermissions();

  let hasPermission = false;
  if (typeof authorized === 'boolean') {
    hasPermission = authorized;
  } else if (requireOneOf) {
    hasPermission = requireOneOf.some(permission => permissions?.[permission]);
  } else if (require) {
    hasPermission = require.every(permission => permissions?.[permission]);
  }

  if ((selectedMembership && !permissions) || !allMemberships) {
    return <Loader />;
  }

  if (!hasPermission) {
    console.log('Does not have permission', require);
    switch (behavior) {
      case 'select membership':
        return (
          <MembershipSelector
            fallback={fallback}
            require={require}
            requireOneOf={requireOneOf}
          />
        );
      default: return <GoBack />;
    }
  }

  return (children || <Outlet />) as JSX.Element;
};

const GoBack = () => <Navigate relative="path" replace to=".." />;

const MembershipSelector = ({ require, requireOneOf, fallback: Fallback }: Pick<ProtectedRouteProps, 'require' | 'requireOneOf' | 'fallback'>) => {
  const memberships = useAllMembershipsWithPermissions();
  const { t } = useTranslation();
  useSetHeaderConfig(null);

  const availableRoles = useMemo(() => {
    if (!memberships) {
      return null;
    }
    const result = [] as typeof memberships;

    memberships.forEach(membership => {
      const matching = membership.roles.filter(
        role => (requireOneOf?.some(key => role.permissions[key])
        || require?.every(key => role.permissions[key])),
      );
      if (matching.length) {
        result.push({
          ...membership,
          roles: matching,
        });
      }
    });

    return result;
  }, [memberships, require, requireOneOf]);

  if (availableRoles && availableRoles.length === 0) {
    return Fallback ? <Fallback /> : <GoBack />;
  }

  return (
    // @ts-expect-error not typed
    <Placeholder loaders={[{ loading: !availableRoles }]}>
      <MembershipsContainer>
        <AllMemberships
          memberships={availableRoles || []}
          skipRouteChange
          title={t('Please choose a membership to continue with RETHiNK')}
        />
      </MembershipsContainer>
    </Placeholder>
  );
};

ProtectedRoute.GoBack = GoBack;
