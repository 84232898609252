import styled from 'styled-components';

export const AppItemLogo = styled.div<{ color: string }>`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: ${({ color }) => color};
  flex-shrink: 0;
`;

export const AppItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
`;

export const AppItemName = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

export const AppItemTitle = styled.div`
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
`;
