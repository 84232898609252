import React, {
  useEffect, useState, useMemo,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Companies } from 'appComponents/Dashboard/Companies';
import { AllMemberships } from 'appComponents/Dashboard/Memberships';
import { Input } from 'appComponents/Input';

import { requireAuth } from 'components/requireAuth';
import { Placeholder } from 'components/Splash/Loading';
import { useCompanies } from 'hooks/useCompany';
import { useDebounce } from 'hooks/useDebounce';
import { useAllMembershipsWithPermissions, useCurrentMembership } from 'hooks/useMemberships';
import { setMembership } from 'state/user/user.actions';

import * as S from './RethinkDashboard.styled';

const RethinkDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [clearInput, setClearInput] = useState(null);
  const allMemberships = useAllMembershipsWithPermissions();
  const { selectedMembership } = useCurrentMembership();
  const membershipsLoading = !allMemberships;

  const [membershipFilterValue, setMembershipFilterValue] = useState('');
  const [companyFilterValue, setCompanyFilterValue] = useState('');

  const canViewChildCompaniesPermission = !selectedMembership && allMemberships?.find(m => m.roles.some(r => r.permissions['company.company_childs.view']));

  const debouncedCompanyFilterValue = useDebounce(companyFilterValue, 1000);
  const companyListing = useCompanies({
    'order[name]': 'asc',
    parentCompany: canViewChildCompaniesPermission?.company?.id,
    name: debouncedCompanyFilterValue,
  }, {
    skip: !canViewChildCompaniesPermission,
    allowWithoutSelectedMembership: true,
  });

  const membershipView = selectedMembership?.view;

  useEffect(() => {
    if (!membershipView && !membershipsLoading) {
      if (allMemberships?.length === 1 && allMemberships[0]?.roles?.length === 1) {
        dispatch(
          setMembership({
            ...allMemberships[0],
            roleName: allMemberships[0]?.roles[0]?.name,
            view: allMemberships[0]?.roles[0]?.view,
          }),
        );
      } else {
        // eslint-disable-next-line no-plusplus
        /* for (let i = 0; i < memberships.length; ++i) {
          const m = memberships[i];
          const u = m?.roles?.find(
            (r) => r?.view === 'Super Admin'
              || r?.view === 'Nibe Employee'
              || r?.view === 'Corporate Admin'
          );
          if (u) {
            const view = u?.view === 'Super Admin'
              ? u?.view?.replace('Super Admin', 'Ultra Admin')
              : u?.view;
            dispatch(
              setMembership({
                ...m,
                roleName: u?.name,
                view: u?.view,
                label: bold(m?.company?.name, view),
                value: `${m?.company?.name} | ${view}`,
              }),
            );
            break;
          }
        } */
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMemberships, membershipView, membershipsLoading]);

  const onFocus = () => setClearInput(null);

  const handleInputChange = (e, type) => {
    const inputText = e.target.value || '';
    if (type === 'name') {
      setMembershipFilterValue(inputText.trim());
    } else {
      setCompanyFilterValue(inputText.trim());
    }
  };

  const memberships = useMemo(
    () => (membershipFilterValue
      ? allMemberships?.filter((m) => m?.company?.name?.toLowerCase().includes(
        membershipFilterValue?.toLowerCase()))
      : allMemberships),
    [allMemberships, membershipFilterValue],
  );

  return (
    <Placeholder loaders={[!!(membershipsLoading)]}>
      {allMemberships && allMemberships?.length <= 6 && !canViewChildCompaniesPermission ? (
        <S.MembershipsContainer>
          <AllMemberships
            memberships={memberships}
            title={t('Please choose a membership to continue with RETHiNK')}
          />
        </S.MembershipsContainer>
      ) : (
        <S.MajorContainer>
          <div>
            <S.DashboardTop>
              <S.Title>{t('your memberships')}</S.Title>
              <Input
                {...clearInput}
                id="name"
                name="name"
                onChange={(e) => handleInputChange(e, 'name')}
                onFocus={onFocus}
                placeholder={t('filter by name')}
                type="search"
              />
            </S.DashboardTop>
            <S.List>
              <AllMemberships memberships={memberships} />
            </S.List>
          </div>
          {!!canViewChildCompaniesPermission && (
            <div>
              <Placeholder loaders={[]}>
                <S.DashboardTop>
                  <S.Title>{t('companies you have access to')}</S.Title>
                  <Input
                    {...clearInput}
                    id="company"
                    name="company"
                    onChange={(e) => handleInputChange(e, 'company')}
                    onFocus={onFocus}
                    placeholder={t('filter by name')}
                    type="search"
                  />
                </S.DashboardTop>
                <S.List>
                  <Companies companyListing={companyListing} />
                </S.List>

              </Placeholder>
            </div>
          )}
        </S.MajorContainer>
      )}
    </Placeholder>
  );
};

export const MembershipSelectorPage = requireAuth(RethinkDashboard);
