import React, { useContext, useEffect, useMemo } from 'react';
import { useLocation, useResolvedPath } from 'react-router-dom';

type HeaderConfig = {
  searchBar?: {
    placeholder: string,
    onSearch: (term: string) => unknown,
  },
  callToAction?: {
    title: string,
    items?: {title: string,
      href?: string, hidden?: boolean}[]
  }
  items:(
    {
      title: string, href: string, hidden?: boolean
    }
    | {
      title: string, items: {title: string, href: string, hidden?: boolean}[]
    }
  )[]
}

type BreadCrumbReplacements = 'hidden' | 'loading' | string | {title: string, href: string}[]
type BreadCrumbReplacementsPerPath = Record<string, BreadCrumbReplacements>

const DEFAULT_HEADER_CONFIG: HeaderConfig = { items: [] };

const SuiteLayoutContext = React.createContext<{
  headerConfig: HeaderConfig,
  setHeaderConfig: React.Dispatch<React.SetStateAction<HeaderConfig>>
  breadCrumbIdReplacements: BreadCrumbReplacementsPerPath,
  _setBreadCrumbIdReplacements: React.Dispatch<
  React.SetStateAction<BreadCrumbReplacementsPerPath>>
}>({
  headerConfig: DEFAULT_HEADER_CONFIG,
  setHeaderConfig: () => null,
  breadCrumbIdReplacements: {},
  _setBreadCrumbIdReplacements: () => null,
});

export const useSuiteLayoutContext = () => useContext(SuiteLayoutContext);

export const useSetHeaderConfig = (config: HeaderConfig | null, deps?: unknown[]) => {
  const { setHeaderConfig } = useSuiteLayoutContext();
  const appPath = useResolvedPath('')?.pathname;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedData = useMemo(() => config, deps || []);

  useEffect(() => {
    if (!memoizedData) {
      setHeaderConfig(DEFAULT_HEADER_CONFIG);
    } else {
      setHeaderConfig({
        ...memoizedData,
        items: memoizedData.items.map(it => {
          if ('href' in it) {
            return it.href.startsWith('/') ? it : {
              ...it,
              href: `${appPath}/${it.href}`,
            };
          }
          return {
            ...it,
            items: it.items.map(sub => (sub.href.startsWith('/') ? sub : ({
              ...sub,
              href: `${appPath}/${sub.href}`,
            }))),
          };
        }),
      });
    }
  }, [memoizedData, setHeaderConfig, appPath]);
};

export const useReplaceBreadCrumbForCurrentPath = (
  ids: BreadCrumbReplacements | null,
  options: {overridePath?: string, idParam?: number | string, skip?: boolean} = {},
) => {
  const { _setBreadCrumbIdReplacements } = useSuiteLayoutContext();
  const location = useLocation();

  const pathname = options.overridePath
  || (options.idParam && location.pathname.indexOf(`${options.idParam}`) >= 0
    ? location.pathname.slice(0, location.pathname.indexOf(`${options.idParam}`) + String(options.idParam).length)
    : null)
  || location.pathname;
  const skip = options.skip || false;

  const stringified = JSON.stringify(ids);
  useEffect(() => {
    if (skip) {
      return;
    }
    const parsed: BreadCrumbReplacements | null = JSON.parse(stringified || 'null');
    _setBreadCrumbIdReplacements(old => {
      if (!parsed) {
        const result = {
          ...old,
        };
        delete result[pathname];
        return result;
      }
      return {
        ...old,
        [pathname]: parsed,
      };
    });

    /* return () => _setBreadCrumbIdReplacements(old => {
      const result = {
        ...old,
      };
      delete result[pathname];
      return result;
    }); */
  }, [stringified, pathname, _setBreadCrumbIdReplacements, skip]);
};

export const SuiteLayoutContextProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [headerConfig, setHeaderConfig] = React.useState<HeaderConfig>({ items: [] });
  const [breadCrumbIdReplacements,
    _setBreadCrumbIdReplacements] = React.useState<BreadCrumbReplacementsPerPath>({});

  const contextValue = useMemo(
    () => ({
      headerConfig, setHeaderConfig, breadCrumbIdReplacements, _setBreadCrumbIdReplacements,
    }),
    [
      headerConfig,
      setHeaderConfig,
      breadCrumbIdReplacements,
      _setBreadCrumbIdReplacements,
    ],
  );
  return (
    <SuiteLayoutContext.Provider value={contextValue}>
      {children}
    </SuiteLayoutContext.Provider>
  );
};
