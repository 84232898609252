import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Divider } from 'appComponents/Divider/styled';

export const Toggle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 12px;
  height: 64px;

  div {
    font-size: 12px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    font-weight: 500;
  }
`;

export const Cta = styled.button`
  background-color: transparent;
  color: #fff;
  padding: 8px 0;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  gap: 4px;
  border: 0;
  width: 100%;
  cursor: pointer;
  font-weight: 600;

  div {
    font-size: 12px;
  }

  img {
    width: 12px;
  }
`;

export const Inner = styled.div`
  margin: 8px;
  min-width: 340px;
`;

export const CtaList = styled.div`
  display: flex;
  max-height: 0;
  overflow: hidden;
  transition: 250ms linear;
  flex-direction: column;
  gap: 4px;
  padding: 0 0 0 52px;
`;

export const CtaListButton = styled.button<{ selected?: boolean }>`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border: 0;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
  color: #fff;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  ${({ selected }) => selected
    && css`
      opacity: 1;
    `}
`;

export const CtaListItem = styled.div<{ expanded: boolean }>`
  display: flex;
  gap: 16px;
  background-color: ${({ expanded }) => (expanded ? '#373C50' : 'transparent')};
  border-radius: 2px;
  padding: 0 8px;

  &:hover {
    ${CtaList} {
      padding: 0 0 8px 52px;
      max-height: 150px;
    }
  }

  > div {
    flex-grow: 1;

    img {
      ${({ expanded }) => expanded
        && css`
          transform: rotate(180deg);
        `}
    }
  }
`;

export const MenuBottom = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

export const MenuLinkBase = css`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  position: relative;

  &:after {
    transition: 250ms linear;
  }

  &:hover {
    &:after {
      content: '';
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 7px;
      top: 7px;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 4px;
    }
  }
`;

export const MenuLink = styled(Link)<{ color?: 'primary' | 'secondary' }>`
  ${MenuLinkBase};
  background-color: #fff;
  color: ${({ color = 'primary', theme }) => (color === 'primary'
    ? `${theme.palette.clay[800]} !important`
    : `${theme.palette.red[400]} !important`)};
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "ReadexPro";

  &:hover {
   text-decoration: underline !important;
  }
`;

export const MenuButton = styled.button`
  ${MenuLinkBase};
  background-color: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
`;

export const DividerStyled = styled(Divider)`
  margin: 0 16px;
`;
