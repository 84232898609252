import { alertToast } from 'config/toast';
import _ from 'lodash';

import network from './network';

const hydraMember = ({ 'hydra:member': data }) => data;
const paginatedMember = ({
  'hydra:member': data,
  'hydra:view': view,
  'hydra:totalItems': totalItems,
}) => ({
  methods: _.map(data, v => ({ ...v, label: v.name, value: v.id })),
  data,
  nextPage: view && view['hydra:next'],
  totalItems,
});

const err = () => { throw new Error('Failed to fetch methods'); };
const deprecated = uri => {
  console.warn(`the endpoint ${uri} has been deprecated.`);
  return Promise.resolve([]);
};

export const all = config => network
  .get('/methods', config)
  .then(paginatedMember)
  .catch(err);

export const getNextPage = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then((methods) => ({
    methods: methods['hydra:member'],
    nextPage: methods['hydra:view'] && methods['hydra:view']['hydra:next'],
    totalItems: methods['hydra:totalItems'],
  }))
  .catch(err);

export const get = id => network
  .get(`/methods/${id}`, { cache: true })
  .catch(err);

export const functionalunits = config => network
  .get('/functional_units', config)
  .then(hydraMember)
  .catch(err);

export const productTypes = config => network
  .get('/product_types', config)
  .catch(() => deprecated('/product_types'));

export const profiles = () => network
  .get('/transport_profiles', { cache: true, params: { itemsPerPage: 200 } })
  .then(hydraMember)
  .catch(err);

export const editMethod = (id, payload) => network
  .put(`/methods/${id}`, payload)
  .then(() => alertToast('Method updated with success!', 'success'))
  .catch(err);
