import React from 'react';

import { AppHeader } from 'appComponents/AppHeader';
import { SuiteLayoutContextProvider } from 'context/SuiteLayoutContext';

export const SuiteLayout = ({ children }: React.PropsWithChildren<unknown>) => (
  <SuiteLayoutContextProvider>
    <AppHeader />
    {children}
  </SuiteLayoutContextProvider>
);
