import styled from 'styled-components';

export const MembershipsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const MembershipGroup = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
  gap: 16px;
`;

export const Name = styled.span`
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;
`;
