import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { REFRESH_DONE, REFRESH_FAILED, REFRESH_LOADING } from 'state/auth/auth.types';

export const useTokenRefresher = () => {
  const { refreshToken, tokenRefreshState } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (refreshToken && tokenRefreshState === 'requested') {
      dispatch({ type: REFRESH_LOADING });
      axios.post(
        `${process.env.REACT_APP_API_URL}/token/refresh`,
        { refreshToken },
      ).then(res => dispatch({
        type: REFRESH_DONE,
        payload: {
          userToken: res?.data?.token,
          refreshToken: res?.data?.refreshToken,
        },
      })).catch(e => {
        if (e?.response?.status === 401) {
          return window.history.pushState({}, '', '/logout');
        }
        console.error(e);
        dispatch({ type: REFRESH_FAILED });
        throw e;
      });
    }
  }, [dispatch, refreshToken, tokenRefreshState]);
};

export const TokenRefreshRequestHandler = () => {
  useTokenRefresher();

  return null;
};
