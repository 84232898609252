import React from 'react';

import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-swr-infinite-scroll';

import { Avatar } from 'appComponents/Avatar';
import { RoleSelector } from 'appComponents/Dashboard/RoleSelector';
import { Loader } from 'appComponents/Loader/styled';
import { useCompanies } from 'hooks/useCompany';
import { useCurrentMembership } from 'hooks/useMemberships';

import * as S from './styled';

export const Companies = ({
  companyListing,
}: {
  companyListing: ReturnType<typeof useCompanies>
  title?: string;
}) => {
  const { changeMembership } = useCurrentMembership();
  const navigate = useNavigate();

  const swr = companyListing?.swr;

  return (
    <InfiniteScroll
      isReachingEnd={!swr?.data?.[swr?.data.length - 1]?.nextPage}
      loadingIndicator={(
        <div style={{
          display: 'flex', alignContent: 'center', justifyContent: 'center', height: '100%',
        }}>
          <Loader size="sm" />
        </div>
      )}
      swr={companyListing.swr}>
      <>
        {/* first child */}
        {swr?.data?.flatMap(d => d.data).map((m) => (
          m && (
            <S.MembershipGroup key={m.id}>
              <Avatar title={m.name} />
              <RoleSelector
                key={m['@id']}
                {...m}
                onClick={async () => {
                  await changeMembership(
                    {
                      // @ts-expect-error we just need this to impersonate a role
                      company: {
                        id: m?.id,
                        name: m?.name,
                        '@id': m?.['@id'],
                      },
                      roleName: 'ROLE_VIEWER',
                      view: 'Viewer',
                      label: 'Viewer',
                      value: 'Viewer',
                    },
                    {
                      name: 'ROLE_VIEWER',
                      view: 'Viewer',
                    },
                  );
                  return navigate('/lca');
                }}
                view="View user"
              />
            </S.MembershipGroup>
          )
        ))}
      </>
    </InfiniteScroll>
  );
};
