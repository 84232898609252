import i18next from 'i18next';

import network from 'services';

import { alertToast } from 'config/toast';

const err = (msg) => {
  throw new Error(msg);
};

export const login = (email, password) => network
  .post(`${process.env.REACT_APP_API_URL}/login_check`, { username: email, password }, {
    crossdomain: true,
    headers: {
      Accept: 'application/ld+json, application/json',
      'Content-Type': 'application/json',
    },
  })
  .catch(() => {
    alertToast(i18next.t('User not found'));
    return err('User not found');
  });

export const password = {
  request: (email) => network.post('/password_request', { email }),

  verifyToken: (token) => network.post('/token_check', { token }),

  reset: (pw, token) => network
    .post('/password_reset', { token, password: pw })
    .then(() => alertToast('Password was reset with success!', 'success')),
};
