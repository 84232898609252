import _ from 'lodash';
import React, { memo } from 'react';
import styled from 'styled-components';

import { Loader } from 'appComponents/Loader/styled';

import { isReady, isReference } from 'utils';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  img {
    width: auto;
    height: 10rem;
    max-width: 100%;
    display: block;
  }
  p {
    font-size: 1.1rem;
    color: #a8a8a8;
  }
`;

/**
 * @deprecated
 */
export const Loading = (props) => (
  <div
    style={{
      height: 'calc(100vh - 4rem)',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    <Loader size={props.size || 'lg'} {...props} />
  </div>
);

export const NoContent = (
  <Wrapper>
    <p>No items found</p>
  </Wrapper>
);

export const CustomNoContent = ({ children }) => (
  <Wrapper>
    <p style={{ textAlign: 'center' }}>{children}</p>
  </Wrapper>
);

export const Placeholder = memo(({
  loaders, children, style, renderChildren, type = 'spinner',
}) => {
  if (isReady(loaders)) {
    return isReference(children) ? children() : children;
  }

  return (
    <div
      key={_.uniqueId('placeholder')}
      style={{
        width: '100%',
        height: '70vh',
        display: 'flex',
        ...style,
      }}>
      {renderChildren && (
        <div style={{ display: 'none' }}>
          {isReference(children) ? children() : children}
        </div>
      )}
      <Loader type={type} />
    </div>
  );
});
