import {
  ErrorBoundary, Provider, useRollbarContext, useRollbarPerson,
} from '@rollbar/react';

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';

import { useCurrentMembership } from 'hooks/useMemberships';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || '',
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT || '',
};

export const RollbarContextProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => (
  // if (!rollbarConfig.accessToken || !rollbarConfig.environment) {
  //  return children as JSX.Element;
  // }
  <Provider config={rollbarConfig}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </Provider>
);
export const RollbarUserTracker = () => {
  // @ts-expect-error not typed
  const userDetails = useSelector(({ user }) => user);
  const { id, email, name } = userDetails || {};
  const { selectedMembership } = useCurrentMembership();

  const memoized = useMemo(() => {
    if (!id || !email || !selectedMembership) return null;
    return {
      id,
      name,
      email,
      membershipId: selectedMembership.id,
      membershipRoleId: selectedMembership.role?.id,
      membershipRoleName: selectedMembership.role?.name,
      membershipCompanyId: selectedMembership.company?.id,
      membershipCompanyName: selectedMembership.company?.name,
    };
  }, [id, email, name, selectedMembership]);

  useRollbarPerson(memoized || {});

  const location = useLocation();

  useRollbarContext(location.pathname);

  return null;
};
