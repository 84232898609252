import _ from 'lodash';

// import { errorToast } from 'utils';
import { alertToast, errorToast } from 'config/toast';

import network from './network';

// const errorToast = alertToast('Companies.js Error', 'error');

const paginatedMember = ({
  'hydra:member': data,
  'hydra:view': view,
  'hydra:totalItems': totalItems,
}) => {
  const arr = _.map(data, item => ({
    ...item,
    label: item.name,
    value: item.name,
  }));

  arr.unshift({ label: 'All', value: 'all' });

  return ({
    companies: arr,
    data: arr,
    nextPage: view && view['hydra:next'],
    totalItems,
  });
};

export const getAll = config => {
  const conf = config || {};
  const params = _.get(config, 'params');
  conf.params = {
    owner: 'none', ...params,
  };
  if (!params?.noItemsPerPage) conf.params.itemsPerPage = 99999999;
  else if (conf.params.noItemsPerPage) delete conf.params.noItemsPerPage;

  return network
    .get('/companies', conf)
    .then(paginatedMember)
    .catch((err) => errorToast(err));
};

export const createCompany = (payload) => network
  .post('/companies', payload)
  .then(() => alertToast('Company created with success!', 'success'))
  .catch((err) => errorToast(err));

export const editCompany = (id, payload) => network
  .put(`/companies/${id}`, payload)
  .then(() => alertToast('Company updated with success!', 'success'))
  .catch((err) => errorToast(err));

export const uploadCompanyLogo = (payload) => network
  .post('/media_objects', payload, {
    params: { owner: 'none' }, fileHeader: { 'Content-Type': 'multipart/form-data' },
  })
  .then(result => {
    alertToast('Company logo uploaded with success!', 'success');
    return result;
  })
  .catch(errorToast);

export const uploadPublisherAutographs = (payload, personNumber) => network
  .post('/media_objects', payload, {
    params: { owner: 'none' }, fileHeader: { 'Content-Type': 'multipart/form-data' },
  })
  .then(result => {
    alertToast(`Publisher autograph (person ${personNumber}) uploaded with success!`, 'success');
    return result;
  })
  .catch(errorToast);

export const deleteCompany = (id) => network
  .delete(`/companies/${id}`)
  .then(() => alertToast('Company deleted with success!', 'success'))
  .catch(errorToast);
