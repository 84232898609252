import styled, { css } from 'styled-components';

import { BaseLinkStyles } from 'appComponents/LinkButton/styled';
import { DARK_BLUE } from 'config/style';

export const whiteButton = css`
  font-style: normal;
  border: 1px solid #e2e2d2;
  background: #fafaf5;
  color: ${DARK_BLUE};
  margin-right: 10px;
`;

export const underlineButton = css`
  background-color: transparent;
  text-decoration: underline;
  border: 0;
  color: ${({ color }) => (color || DARK_BLUE)};
  font-weight: 400;
  border-radius: 4px;
  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    background-color: #eee;
  }
`;

export const styledSelect = css`
  font-size: .75rem;
  color: #fff;
  background: ${({ theme }) => theme.palette.blue[300]};
  border: 0;
  padding: 0 1.1rem;
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  min-height: 40px;
  min-width: 5rem;

  &:hover, &:focus {
    background-color: #0F3EA6;
  }
`;

export const StyledButton = styled.button`
  padding: .68rem 1.25rem;
  cursor: pointer;
  background: ${DARK_BLUE};
  color: white;
  font-size: 0.75rem;
  border: none;
  font-weight: normal;
  min-width: 5rem;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 auto;

  &:disabled {
    opacity: .45;
    cursor: not-allowed;
  }

  > div {
    flex: 0 1 100%;
  }

  ${(props) => props.styling === 'select' && styledSelect};
  ${({ styling }) => styling === 'white' && BaseLinkStyles};
  ${({ rounded, theme }) => rounded && css`
    background-color : white;
    box-shadow: ${theme.elevation.light};

    &:hover, &:focus {
      background-color: white;
    }
  `};
  ${({ styling }) => styling === 'underline' && underlineButton};
`;

export const Icon = styled.i`
  flex: 0 0 auto;
  display: inline-flex;
  ${({ left }) => !left && css`margin-left: .75em;`};
  ${({ left }) => left && css`margin-right: .75em;`};
`;
