import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { users } from 'services';

import { Loading } from 'components/Splash/Loading';
import { getUser } from 'state/user/user.actions';

export const requireAuth = (ChildComponent) => {
  const ComposedComponent = (props) => {
    const { location } = props;
    const { userToken, email } = useSelector(state => state.auth);
    const { id: userId } = useSelector(state => state.user);
    const dispatch = useDispatch();

    useLayoutEffect(() => {
      function fetchUser() {
        users.getMe()
          .then(result => result && getUser([result], email))
          .then(dispatch);
      }
      if (userToken) fetchUser();
    }, [dispatch, userId, email, userToken]);

    if (!userToken) return <Navigate to={{ pathname: '/login', state: { from: location } }} />;
    if (!userId) return <Loading />;

    return <ChildComponent {...props} />;
  };

  return ComposedComponent;
};

/**
 * @deprecated use named export instead
 */
export default requireAuth;
