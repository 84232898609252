import React from 'react';

import membershipsSvg from 'assets/memberships.svg';

import * as S from './styled';

export const RoleSelector = ({ view, onClick }: {view: string; onClick: () => void}) => (
  <S.CardContainer
    onClick={onClick}>
    <S.Role>{view}</S.Role>
    <S.Options alt="icon" src={membershipsSvg} />
  </S.CardContainer>
);
