import React, {
  Dispatch, SetStateAction, useEffect, useRef,
} from 'react';

import { useLocation } from 'react-router-dom';

import { useDetectOutsideClick } from './hooks';
import * as S from './styled';
import ChevronDown from '../../assets/icons/chevron-down.svg';

interface DropdownBasicProps {
  isOpened?: boolean;
  handleOpen?: Dispatch<SetStateAction<boolean>>;
  align?: 'left' | 'right';
  offset?: 'small' | 'large';
  variant?: 'primary' | 'secondary';
  color?: 'primary' | 'secondary';
}

interface DropdownWithTitleProps extends DropdownBasicProps {
  toggleTitle?: string;
}

interface DropdownWithToggleComponentProps extends DropdownBasicProps {
  toggleComponent?: React.ReactNode;
}

type DropDownProps = DropdownWithTitleProps & DropdownWithToggleComponentProps;

export const Dropdown: React.FC<DropDownProps> = ({
  isOpened = false,
  handleOpen,
  toggleTitle,
  toggleComponent,
  align = 'left',
  offset = 'large',
  variant = 'primary',
  color = 'primary',
  children,
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const { opened, setIsOpened } = useDetectOutsideClick(dropDownRef, isOpened);

  const location = useLocation();

  useEffect(() => {
    setIsOpened(false);
  }, [location, setIsOpened]);

  useEffect(() => {
    setIsOpened(isOpened);
  }, [isOpened, setIsOpened]);

  useEffect(() => {
    if (handleOpen) {
      handleOpen(opened);
    }
  }, [opened, handleOpen]);

  return (
    <S.Wrapper ref={dropDownRef}>
      <S.Toggle
        offset={offset}
        onClick={() => setIsOpened((state) => !state)}
        onMouseEnter={() => setIsOpened((state) => !state)}
        withTitle={!!toggleTitle}>
        {toggleTitle ? (
          <>
            {toggleTitle}
            <img alt="" src={ChevronDown} />
          </>
        ) : (
          toggleComponent
        )}
      </S.Toggle>
      <S.DropDown
        align={align}
        color={color}
        offset={offset}
        variant={variant}>
        {variant === 'secondary' && <S.Arrow color={color} />}
        {children}
      </S.DropDown>
    </S.Wrapper>
  );
};
