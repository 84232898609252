import styled, { css } from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  color: ${({ theme }) => theme.palette.clay[800]};
`;

export const InputLabel = styled.label`
  font-size: 12px;
`;

export const InputError = styled.span`
  font-size: 12px;
`;

export const InputHelperText = styled.span`
  font-size: 12px;
  display: block;
`;

export const InputWrapper = styled.div`
  position: relative;

  img {
    position: absolute;
    width: 16px;
    left: 24px;
    top: 13px;
  }
`;

export const InputElement = styled.input<{isSearch: boolean}>`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.clay[300]};
  padding: 12px 16px;
  font-size: 12px;
  width: 100%;
  color: ${({ theme }) => theme.palette.clay[800]};
  font-weight: 400;
  font-family: inherit;
  height: 40px;

  ::placeholder {
    color: #aaaaaa;
  }

  &:focus,
  &:hover {
    border-color: rgba(24, 84, 217, 0.3);
  }

  ${({ isSearch }) => isSearch && css`
    padding-left: 46px;
  `}
`;
