import React, { useState } from 'react';

import { Membership } from 'types/Membership';

import * as S from './styled';
import { UserMenuItem } from './UserMenuItem';

export const UserMenuAccordion = ({
  availableMemberships,
  handleClose,
}: {
  availableMemberships: Membership[];
  handleClose: () => void;
}) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>();

  return (
    <S.Inner>
      {availableMemberships.map((membership, index) => (
        <UserMenuItem
          key={membership['@id']}
          expanded={expandedIndex === index}
          handleClick={() => {
            setExpandedIndex(expandedIndex === index ? null : index);
          }}
          handleClose={handleClose}
          membership={membership}
        />
      ))}
    </S.Inner>
  );
};
