import styled from 'styled-components';

export const SearchContainer = styled.form`
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 5px;
  height: 40px;
  background-color: ${({ theme }) => theme.palette.blue[50]};
  width: 190px;
  padding-left: 46px;
  position: relative;
  transition: 150ms;
  border: 1px solid transparent;

  &:hover,
  &:focus-within {
    border: 1px solid ${({ theme }) => theme.palette.blue[300]};
  }

  button {
    position: absolute;
    left: 20px;
    top: 7px;
    border: 0;
    background-color: transparent;
    padding: 5px;
    display: flex;
    cursor: pointer;

    img {
      width: 16px;
    }
  }
`;

export const SearchInput = styled.input`
  border: none;
  flex: 1;
  font-size: 12px;
  background-color: transparent;

  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  ::-ms-input-placeholder,
  ::placeholder {
    color: ${({ theme }) => theme.palette.clay[500]};
  }
`;
