import React from 'react';

import ReactAvatar from 'react-avatar';

import * as S from './styled';

type AvatarProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  imageUrl?: string;
  size?: string;
  title: string;
  label?: string
};

export const Avatar = ({
  size = '32px', title, label,
}: AvatarProps) => (
  <S.AvatarContainer>
    <ReactAvatar name={title} round size={size} />
    <span>{label || title}</span>
  </S.AvatarContainer>
);
