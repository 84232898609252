import styled from 'styled-components/macro';
import { InputWidth } from 'config/style';

import { Label as GlobalLabel } from '../../routes/GlobalStyle.styled';

export const WYSIWYG_EDITOR_DESIRED_WIDTH = 715;

export const FieldWrapper = styled.div`
  margin-bottom: 0;
  width: 100%;

  label:empty {
    display: none;
  }

  label {
    label {
      margin: 0;
    }
  }
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const LabelWrapper = styled.div`
  max-width: ${InputWidth};
`;

export const Hint = styled.p`
  padding: 1rem;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #fff;

  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    right: 19px;
    background-color: #000;
    transform: rotate(45deg);
    bottom: -5px;
  }
`;

export const Label = styled(GlobalLabel)`
  display: inline-flex;
  align-items: center;
  position: relative;
  gap: 4px;

  svg {
    cursor: pointer;

    &:hover + div {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const MaxWidth = styled.div`
  max-width: ${({ fullInputWidth }) => (fullInputWidth ? InputWidth : `${WYSIWYG_EDITOR_DESIRED_WIDTH}px`)};
`;

export const ReadOnlyLabel = styled(Label)`
  font-size: 0.75rem;
  font-weight: 600;
`;

export const ErrorContainer = styled.div`
  color: #CD3E00;
  font-size: 0.675rem;
  margin-top: 4px;
`;

export const HintWrapper = styled.div`
  position: absolute;
  right: -1rem;
  bottom: 32px;
  background-color: #000;
  color: #fff;
  min-width: 400px;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
`;

export const ExpandForm = styled.div`
  width: auto!important;

  label label {
    margin: 0;
  }

`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  font-size: 0.75rem;
  font-weight: normal;
  font-weight: 400;

  & > * {
    width: 100%;
    flex-shrink: 0;
  }

  &.invalid {
    input, textarea {
      border-color: rgba(205, 62, 0, 0.3);
    }

    > svg {
      position: absolute;
      right: 16px;
      width: 14px;
      top: 13px;
    }
  }

  // radio buttons
  ${FieldWrapper} {
    margin-bottom: 8px;

    &:last-child {
      margin-top: 0;
    }
  }
`;

export const StyledLabel = styled.p`
  font-size: 0.75rem;
  margin-bottom: 8px;
  font-weight: 500;
`;

export const ContentWrapper = styled.p`
  font-weight: 100;
  font-size: 0.75rem;
`;
