import React, { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// import hero from 'assets/rethink-hero.jpg';
import { LinkButton } from 'appComponents/LinkButton';

import { ReactComponent as Logo } from 'assets/logo.svg';
import Footer from 'components/Footer/Footer';
import Input from 'components/Input/Input';
import SwitchToggle from 'components/SwitchToggle/SwitchToggle';
import * as authActions from 'state/auth/auth.actions';

import * as S from './Login.styled';

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const h = useNavigate();
  const emailField = createRef();
  const passwordField = createRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [didSubmit, setSubmit] = useState(true);

  // Switch Toggle State
  const [isChecked, setIsChecked] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = {
      email: emailField.current.value,
      password: passwordField.current.value,
      persist: isChecked,
    };

    setSubmit(false);
    return dispatch(authActions.login(payload))
      .then(() => h('/'))
      .catch(() => {
        setErrorMessage(t('wrong credentials'));
        setSubmit(true);
      });
  };

  return (
    <S.StyledContainer>
      <S.InputContainer onSubmit={onSubmit}>
        <S.StyledColumn>
          <S.StyledTitle>
            {t('welcome')}
            <Logo />
          </S.StyledTitle>

          <Input
            autoComplete="email"
            error={errorMessage}
            id="email"
            innerRef={emailField}
            placeholder={t('email address')}
            required
            style={{ marginBottom: '1.7rem' }}
            type="email"
          />

          <Input
            autoComplete="password"
            error={errorMessage}
            id="password"
            innerRef={passwordField}
            placeholder={t('password')}
            required
            type="password"
          />

          <S.ButtonContainer>
            <S.MessageContainer>
              {errorMessage && (
                <S.StyledError>{errorMessage}</S.StyledError>
              )}
              <S.StyledLink>
                <Link to="/forgot">{t('forgot password')}</Link>
              </S.StyledLink>
            </S.MessageContainer>

            <LinkButton as="button" disabled={!didSubmit} type="submit">
              {didSubmit ? t('login') : t('submitting')}
            </LinkButton>
          </S.ButtonContainer>

          <S.RememberWrapper>
            <SwitchToggle checked={isChecked} onChange={() => setIsChecked(!isChecked)} />

            <S.Text>{t('remember credentials')}</S.Text>
          </S.RememberWrapper>
        </S.StyledColumn>

        <Footer />
      </S.InputContainer>
    </S.StyledContainer>
  );
};

export default Login;
