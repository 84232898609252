import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as moment from 'moment';
import 'moment/locale/nl';

import { store } from 'state';

import en from './en';
import de from './de';
import nl from './nl';

export const resources = {
  en,
  de,
  nl,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: store.getState().user.locale,
    fallbackLng: ['en', 'nl'],

    interpolation: {
      escapeValue: false, // react already safes from XSS
    },
  });

moment.locale(i18n.language);

export const reportingLanguages = t => [
  {
    value: 'en',
    label: t('en'),
  },
  {
    value: 'nl',
    label: t('nl'),
  },
  {
    value: 'de',
    label: t('de'),
  },
];
