import React from 'react';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { Avatar } from 'appComponents/Avatar';
import { RoleSelector } from 'appComponents/Dashboard/RoleSelector';
import { RethinkSuiteLayerGuards } from 'apps/guards';
import { useAllMembershipsWithPermissions, useCurrentMembership } from 'hooks/useMemberships';

import * as S from './styled';

type MembershipsWithPermissions = NonNullable<ReturnType<typeof useAllMembershipsWithPermissions>>;
type MembershipWithPermissions = MembershipsWithPermissions[number];

export const AllMemberships = ({
  memberships,
  title,
  skipRouteChange,
}: {
  memberships: MembershipsWithPermissions;
  title?: string;
  skipRouteChange?: boolean
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { changeMembership } = useCurrentMembership();

  const onMembershipSelected = (membership: MembershipWithPermissions, role: MembershipWithPermissions['roles'][number]) => {
    changeMembership(membership, role);
    if (skipRouteChange) {
      return;
    }
    const destination = Object.entries(RethinkSuiteLayerGuards)
      .find(layer => layer[1].some(perm => role.permissions[perm]))?.[0];

    navigate(`/${destination}`);
  };

  return memberships ? (
    <>
      { title && (
        <S.Name>
          {title}
        </S.Name>
      ) }
      {memberships.map((group) => (
        <S.MembershipGroup key={group.id}>
          <Avatar
            title={group.company.name}
          />
          <S.MembershipsList>
            {group.roles.map((m) => (
              <RoleSelector
                key={m['@id']}
                {...m}
                onClick={() => onMembershipSelected(group, m)}
              />
            ))}
          </S.MembershipsList>
        </S.MembershipGroup>
      ))}
    </>
  ) : (
    <S.Name>{t('no active memberships')}</S.Name>
  );
};
