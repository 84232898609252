import { LIGHT_BLUE } from 'config/style';
import React, { memo } from 'react';
import styled from 'styled-components';

const Article = styled.article`
  ul {
    margin-inline-start: 20px;
  }
  ol {
    margin-inline-start: 20px;
  }

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }

  figure {
    margin-left: 0;
    margin-right: 0;
    table {
      width: 100%;
      border-collapse: collapse;
      
      th {
        background-color: ${LIGHT_BLUE};
        padding: 8px;
        text-align: left;
        white-space: nowrap;
        font-weight: 600;
      }

      tbody {
        tr {
          border-bottom: 1px solid ${LIGHT_BLUE};
          td {
          padding: 8px;
        }
        }
      }
    }
  }
`;

Article.defaultProps = {
  className: 'with-p-margins',
};

const RenderHTML = ({ children, html, ...props }) => (
  <Article dangerouslySetInnerHTML={{ __html: html || children }} {...props} />
);

export default memo(RenderHTML);
