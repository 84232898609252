import React, { ChangeEvent, FC, useState } from 'react';

import searchIcon from 'assets/icons/search.svg';

import * as S from './styled';

interface SearchProps {
  placeholder: string;
  onSearch: (t: string) => void;
}

export const Search: FC<SearchProps> = ({ placeholder, onSearch }) => {
  const [searchValue, setSearchValue] = useState('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <S.SearchContainer onSubmit={(e) => {
      e.preventDefault(); e.stopPropagation();
      onSearch(searchValue);
      setSearchValue('');
    }}>
      <button type="submit">
        <img alt="" src={searchIcon} />
      </button>
      <S.SearchInput
        onChange={handleChange}
        placeholder={placeholder}
        type="search"
        value={searchValue}
      />
    </S.SearchContainer>
  );
};
