import { saveAs } from 'file-saver';

import _ from 'lodash';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { alertToast } from 'config/toast';
import { versionedApi } from 'services/network';
import { store } from 'state';

export const isReady = loaders => !_.some(loaders, entry => {
  const { loading, status } = entry;
  if (status === 'not-requested') return true;
  if (status === 'loading') return true;
  if (status === 'error') return false;
  if (_.has(entry, 'loading')) return loading; // success
  return entry;
});

export function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 *
 * @param {string} url
 * @param {string | null | undefined} fileName
 * @param {unknown | undefined} [setLoading]
 * @returns
 */
export const downloadFile = (url, fileName = null, setLoading) => versionedApi.get(url, {
  headers: {
    Authorization: `Bearer ${store?.getState()?.auth?.userToken}`,
    'Nibe-CompanyId': store?.getState()?.user?.selectedMembership?.company?.id,
    'Nibe-View': store?.getState()?.user?.selectedMembership?.view,
  },
  responseType: 'blob',
  removeNibeHeaders: true,
}).then(async data => saveAs(data, fileName))
  .catch((error) => {
    console.error(error);
    alertToast('The file couldn’t be downloaded', 'error');
  })
  .finally(() => {
    if (setLoading) setLoading(false);
  });

/**
 * use this when you need to show an image, because it requires headers
 */
export const showFile = (url) => versionedApi.get(url, {
  responseType: 'blob',
  removeNibeHeaders: true,
  headers: {
    Authorization: `Bearer ${store?.getState()?.auth?.userToken}`,
    'Nibe-CompanyId': store?.getState()?.user?.selectedMembership?.company?.id,
    'Nibe-View': store?.getState()?.user?.selectedMembership?.view,
  },
}).then(async response => window.URL.createObjectURL(response));

export const isReference = element => typeof element === 'function';

export const inputName = ({
  description,
  environmentalProfile,
  supplier,
  netAmount,
}) => `
  ${description || ''}
  ${environmentalProfile?.name ? ` | ${environmentalProfile?.name}` : ''}
  ${supplier?.name ? ` | ${supplier?.name}` : ''}
  | ${netAmount || ''}
`;

export const bold = (companyName, view) => React.createElement(
  'span',
  {
    // eslint-disable-next-line max-len
    dangerouslySetInnerHTML: { __html: `<div><div style="display:inline;font-weight:500;color:#202538;">${companyName} | </div><div style="display:inline;">${view}</div></div` },
  },
);
