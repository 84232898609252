import i18next from 'i18next';
import _ from 'lodash';
import * as moment from 'moment';

import { getCompany } from 'state/company/company.actions';

import * as types from './user.types';

const updateLocale = locale => {
  i18next.changeLanguage(locale, err => {
    if (err) throw new Error('Error at changing language');
  });

  moment.locale(locale);
};

export const getUser = (usersList, email, selectedCompany) => dispatch => {
  // eslint-disable-next-line max-len
  const user = _.find(usersList, value => (value?.email?.replace('ã', 'a').toLowerCase() === email?.toLowerCase()));

  const {
    locale, name, id, username,
    companyAdmin, superAdmin, reviewer, reviewerAdmin, autographImage, companies,
  } = user || {};

  updateLocale(locale);

  if (!selectedCompany) dispatch(getCompany(usersList));

  dispatch({
    type: types.GET_USER,
    payload: {
      username,
      email: username,
      locale,
      name,
      id,
      companyAdmin,
      superAdmin,
      reviewer,
      reviewerAdmin,
      autographImage,
      companies,
    },
  });
};

export const editUser = (payload) => {
  if (payload?.locale) updateLocale(payload.locale);

  return {
    type: types.UPDATE_USER,
    payload,
  };
};

export const setMembership = (selectedMembership) => ({
  type: types.SET_USER_MEMBERSHIP,
  payload: { selectedMembership },
});

export const addMembership = (isRecentMembership) => ({
  type: types.SET_USER_MEMBERSHIP,
  payload: { isRecentMembership },
});
