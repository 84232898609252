import styled from 'styled-components';

export const Divider = styled.div<{ color?: 'white' | 'light' | 'dark' }>`
  height: 1px;
  opacity: 0.2;
  background-color: ${({ color = 'light', theme }) => {
    switch (color) {
      case 'light':
        return '#fff';
      case 'dark':
        return theme.palette.clay[800];
      default:
        return '#fff';
    }
  }};
`;
