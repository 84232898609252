import React from 'react';
import styled from 'styled-components';

export const ContentLoader: React.FC<{
  lines?: number;
  noPadding?: boolean;
}> = ({ lines = 4, noPadding }) => (
  <Wrapper noPadding={noPadding}>
    {Array.from({ length: lines }).map((_, i) => (
      <Container key={i.toString()} />
    ))}
  </Wrapper>
);

const Wrapper = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 32px;

  ${({ noPadding }) => noPadding
    && `
    padding: 0;
  `}
`;

const Container = styled.div`
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: red;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  height: 30px;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  margin: 4px 0;
  width: 100%;
  flex-direction: column;
`;
