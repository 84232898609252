import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';

import type React from 'react';

type UseDetectOutsideClickType = {
  opened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
};

export const useDetectOutsideClick = (
  elementRef: React.RefObject<HTMLDivElement>,
  initialState: boolean,
): UseDetectOutsideClickType => {
  const [opened, setIsOpened] = useState<boolean>(initialState);

  useEffect(() => {
    const handleClickOutside = (event: Event): void => {
      if (
        elementRef?.current
        && !elementRef.current.contains(event.target as HTMLElement)
      ) {
        setIsOpened(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [elementRef]);

  return {
    opened,
    setIsOpened,
  };
};
